.App {
  text-align: center;
}

.Toastify__toast-container--top-center {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}


  .App-logo {
    height:50px;
    width:50px;

  }



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* The CSS */
.container {
  display: none;
  flex-direction: column;
  position: fixed;
  background-color: #f37736;
  width: 230px;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 0.3rem;
  padding: 1rem;
  z-index: 5; /* Higher than the z-index of the background */
}

.confirmation-text {
  display: flex;
  color: white;
  margin: 0.5rem 0 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 1.1rem;
}

.button-container {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
}

.confirmation-button, delete-button {
  display: inline-flex;
  background-color: #cc0000;
  color: white;
  padding: 0.7rem 1.4rem;
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
}

.cancel-button {
  background-color: #999999;
}

.confirmation-button:hover {
  background-color: red;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #b2b2b2;
  cursor: pointer;
}
