body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Poppins";
}

h2 {
  font-size: 2rem;
}
.content-pointer-event-none {
  pointer-events: none;
}

.input-wrapper {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 80%;
  margin-bottom: 15px;
  font-family: "Poppins";
}

.icon {
  padding: 10px;
  background: #333333;
  color: white;
  min-width: 50px;
  text-align: center;
  font-family: "Poppins";
}
.toaster {

  text-align: center;
 
}

.input-field {
  width: 80%;
  padding: 10px;
  outline: none;
  font-family: "Poppins";
  border: 2px solid #333333;
}

.input-field:focus {
  box-shadow: 0 0 10px #333333;
  border: 2px solid #333333;
}

.input-wrapper textarea {
  padding: 1rem;
  border: 2px solid #333333;
  height: 400px; 
  width: 80%;
}

.input-wrapper textarea:focus {
  outline: none;
  box-shadow: 0 0 10px #333333;
  border: 2px solid #333333;
}

.attachment-wrapper {
  margin: 15px 0;
}

.attachment-wrapper label {
  font-weight: 600;
}

.send-btn {
  background-color: #333333;
  color: white;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  font-size: 16px;
}

.send-btn:hover {
  opacity: 1;
}

.editable {
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  padding: 5px;
  resize: both;
  overflow: auto;
}
